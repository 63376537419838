import React from 'react'
import terms from '../assets/terms-bg1.png';

const Terms = () => {
  return (
    <div>
      <div  style={{ 
      backgroundImage: `url(${terms})` }} className=" h-[300px] w-full mb-6">
        <h1 className='  text-center font-extrabold pt-24 underline text-5xl text-white'>Terms of use</h1>
      </div>

      <div className=" ml-20 mr-20 ">

      <div className=" mb-8">
        <h3 className=' font-bold text-lg mb-3'>Acceptance of Terms</h3>
        <p className=' text-base font-light '>By accessing or using any part of the Move Network or the services provided on it or other Web sites, Web pages or other applications as set forth below (collectively, the “Services”), you agree to accept and comply with the terms, conditions, and notices stated herein and as may be modified by Move from time-to-time without notice to you (the “Terms of Use”). These Terms of Use constitute a binding contract between Move and you. You are responsible for regularly reviewing the Terms of Use. You can review the most current version of the Terms of Use at any time at: http://localhost:3000/terms If you do not wish to be bound by these Terms of Use, please do not access or use any part of the Move Network.</p>
      </div>

      <div className="mb-8">
        <h3 className=' font-bold text-lg mb-3'>Ability to Opt Out from Receiving Certain Communications.</h3>
        <p className=' text-base font-light '>You have the choice to opt out of receiving certain marketing communications (email or text messages) you might receive from Move or a Move division (each referred to here as a "Sender"). Normally, a marketing email you receive from a Sender will have an opt-out link provided within the email itself (usually toward the bottom), and you typically can opt out of receiving further such marketing emails by clicking on the opt-out link and indicating your decision. If you receive marketing texts from a Sender, you typically can opt out of receiving further such texts by following instructions provided in the text itself, often by texting "STOP" to 22445, or by emailing the Move Customer Care team at prajapativedant908@gmail.com and including sufficient information to allow the Sender to properly respond to your request (e.g., your full name, your complete phone number with area code, and an image or description of the marketing text you received). Whether it's email or text, your request will be handled promptly; but please note that for a reasonable time you might receive further such marketing emails or texts while the Sender processes your request. Note also that opting out of marketing emails or texts will not necessarily cause you to stop receiving non-marketing emails or texts, such as texts about a purchase you may have made, a transaction you may have with a Sender, a lead you have submitted about a property that is for sale or perhaps even about listing alerts you may have requested, as well as other types (for example, about technical, administrative or informational matters) -- but even some or all of these kinds of messages (especially if texts) the Sender may stop as well.</p>
      </div>

      <div className="mb-8">
        <h3 className=' font-bold text-lg mb-3'>Interpretation of the Terms of Use</h3>
        <p className=' text-base font-light '>If any part of the Terms of Use is determined to be invalid or unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers and liability limitations contained herein, then the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of the Terms of Use shall continue in effect. Unless otherwise specified herein (or in any other definitive written agreement between you and Move), the Terms of Use constitutes the entire agreement between you and Move with respect to the Move Network and it supersedes all prior or contemporaneous communications and proposals, whether electronic, oral or written, between you and Move with respect to the Move Network. A printed version of the Terms of Use and of any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to the Terms of Use to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form.</p>
      </div>

      <div className="mb-8">
        <h3 className=' font-bold text-lg mb-3'>Copyright</h3>
        <p className=' text-base font-light mb-3'>All materials on the Move Network (as well as the organization and layout of the Move Network) are owned and copyrighted or licensed by Move, its corporate affiliates or its third-party vendors. © by Move, Inc., all rights reserved. No reproduction, distribution, or transmission of the copyrighted materials on the Move Network is permitted without the written permission of Move. Any rights not expressly granted herein are reserved.</p>
        <p className='text-base font-light'>Pursuant to Title 17, United States Code, Section 512, notifications of claimed copyright infringement should be sent to Move’s Designated Agent set forth below. All inquiries must be filed in accordance with Title 17, United States Code, Section 512.</p>
      </div>

      </div>
    </div>
  )
}

export default Terms
