import React from 'react'
import logo from "../assets/logo4.png"
import { useNavigate } from 'react-router'
import {  HiPhone } from "react-icons/hi";
import { MdEmail } from 'react-icons/md';
import { FaLink, FaUser } from 'react-icons/fa';

const Footer = () => {
    const navigate = useNavigate()
    
    return (
        <footer class="p-4 bg-white sm:p-6 dark:bg-gray-800">
            <div class="flex justify-around max-md:flex-col max-md:justify-start max-md:flex-wrap max-md:items-start gap-8 max-sm:justify-center" >

                <div class=" justify-start mb-6 md:mb-0 max-sm:hidden max-md:hidden">
                <img src={logo} alt="logo" className='ml-8 w-40 cursor-pointer' onClick={() => navigate("/")} />
                </div>

                
                    <div className=' flex-col pr-16 mr-20'>
                        <h3 class="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Contact Us</h3>
                        <ul>
                            <a href='tel:9601533689' class="flex gap-1 items-center mb-4 text-gray-600 hover:underline dark:text-gray-400">
                                <HiPhone className=' text-2xl'/> +91 9601533689
                            </a>
                            <a href="mailto:prajapativedant908@gmail.com" class=" flex gap-1 items-center mb-4 text-gray-600 hover:underline dark:text-gray-400">
                                {/* <a href="mailto:prajapativedant908@gmail.com"> */}
                                     <MdEmail className=' text-2xl'/> prajapativedant908@gmail.com
                                {/* </a> */}
                            </a>
                        </ul>
                    </div>

                    <div>
                        <h3 class="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">legal</h3>
                        <ul>
                        <li class=" flex gap-1 items-center mb-4 text-gray-600 hover:underline dark:text-gray-400" onClick={() => navigate("/about")}>
                                <FaUser className=' text-2xl'/> About us
                            </li>
                            <li class=" flex gap-1 items-center mb-4 text-gray-600 hover:underline dark:text-gray-400" onClick={() => navigate("/terms")}>
                            <FaLink className=' text-2xl'/> Safety Privacy & Terms  
                            </li>
                        </ul>
                    </div>


            </div>

            <hr class="my-4 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />

            <div className=" dark:text-white flex flex-col font-semibold text-center">
                <span>Copyright ©2022 by VHomes, Inc.</span>
                <br />
                <span>All rights reseved.</span>
            </div>

        </footer>
    )
}

export default Footer
