import React from 'react'
import About from '../assets/one.mp4';
import about from '../assets/about1.png';


const AboutAs = () => {
  return (
    <div className=' bg-white'>
      <div className="mb-6">
        <video className=' w-full h-[420px] aspect-video mb-3 max-sm:h-full'
          controls
          src={About}
        />
        <img src={about} alt="about" className='w-full' />
      </div>

      <div className="">
        <h1 className=' justify-center text-center font-extrabold text-4xl mb-5 max-md:font-bold max-md:text-lg'>About VHomes.</h1>
        <p className=' text-2xl font-light ml-60 mr-60 mb-3 content-center	 max-md:font-normal max-md:text-xs max-md:m-0 max-md:mb-4 '>For years, millions of home shoppers have turned to VHomes. to find their dream home. Operated by Move, Inc., VHomes.® offers a comprehensive list of for-sale properties, as well as the information and tools to make informed real estate decisions. Today, more than ever, VHomes. is The Home of Home Search℠.</p>
        <p className=' text-2xl font-light ml-60 mr-60 mb-4  max-md:font-normal max-md:mb-4 max-md:text-xs max-md:m-0'>VHomes. also offers homeowners a bevy of useful tools and resources through the My Home℠ dashboard. My Home℠ dashboard allows property owners to manage their home like the important investment it is by tracking their home’s value over time, researching and managing home improvements, and scouting other similar properties in the neighborhood.
        </p>
      </div>

    </div>
  )
}

export default AboutAs
